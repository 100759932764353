import * as Yup from 'yup'
import React, {useState} from 'react'
import {useFormik, Form, FormikProvider} from 'formik'
// material
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Rating from '@mui/material/Rating'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import {LoadingButton} from '@mui/lab'
import {useSnackbar} from 'notistack'

// api
import gigs_api from 'libs/endpoints/gigs'

import {fCamelCase} from 'utils/formatCase'

import PropTypes from 'prop-types'
import {Star, StarBorder} from '@mui/icons-material'

FreelancerRating.propTypes = {
  user: PropTypes.object,
  gigId: PropTypes.string,
  onClose: PropTypes.func
}

export default function FreelancerRating({gigId, onClose}) {
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setLoading] = useState(false)
  const [RATING, setRating] = useState({
    efficiency: {},
    recommendable: {},
    completeness: {},
    showRate: {}
  })

  const GigSchema = Yup.object().shape({
    comments: Yup.string()
  })

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: GigSchema,
    onSubmit: async (values) => {
      setLoading(true)
      let isComplete = true

      Object.keys(RATING).forEach((item) => {
        let field = RATING[item]
        if (item === 'notes') return

        if (!field) {
          isComplete = false
          enqueueSnackbar(`Required field ${fCamelCase(item)}`, {variant: 'warning'})
        }
      })

      if (!isComplete) {
        return setLoading(false)
      }

      let data = {
        ...RATING,
        comments: values.comments || '',
        _id: gigId
      }
      const request = await gigs_api.post_rating_gig(data._id, data)
      if (!request.ok) {
        enqueueSnackbar(`Something went wrong in submitting  your rating/feedback`, {variant: 'error'})
        return setLoading(false)
      }

      setLoading(false)
      enqueueSnackbar(`Thank you for your feedback/rating!`, {variant: 'success'})
      onClose()
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000)
    }
  })

  const {handleSubmit, getFieldProps} = formik

  const handleRating = (value, type) => {
    setRating((prev) => ({...prev, [type]: value}))
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{mb: 2, position: 'flex', alignItems: 'center'}}>
          <Typography variant="h5" color="text.secondary" sx={{display: 'block', fontWeight: '600'}}>
            Show Rate
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{mt: '0 !important'}}>
            The Jobster show up on the agreed gig schedule
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={RATING.showRate}
            onChange={(event, newValue) => {
              handleRating(newValue, 'showRate')
            }}
          >
            <Stack direction="row">
              <FormControlLabel
                sx={{m: '0 !important', position: 'flex', alignItems: 'center'}}
                value={1}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'gold'}} />}
                    checkedIcon={<Star sx={{color: 'gold'}} />}
                  />
                }
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'black'}} />}
                    checkedIcon={<Star sx={{color: 'black'}} />}
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </Stack>
        <Stack spacing={3} sx={{mb: 1, position: 'flex', alignItems: 'center'}}>
          <Typography variant="h5" color="text.secondary" sx={{display: 'block', fontWeight: '600'}}>
            Completeness
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{mt: '0 !important'}}>
            The Jobster complete the agreed given task
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={RATING.completeness}
            onChange={(event, newValue) => {
              handleRating(newValue, 'completeness')
            }}
          >
            <Stack direction="row">
              <FormControlLabel
                sx={{m: '0 !important', position: 'flex', alignItems: 'center'}}
                value={1}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'gold'}} />}
                    checkedIcon={<Star sx={{color: 'gold'}} />}
                  />
                }
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'black'}} />}
                    checkedIcon={<Star sx={{color: 'black'}} />}
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </Stack>
        <Stack spacing={3} sx={{mb: 1, position: 'flex', alignItems: 'center'}}>
          <Typography variant="h5" color="text.secondary" sx={{display: 'block', fontWeight: '600'}}>
            Efficiency
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{mt: '0 !important'}}>
            The Jobster perform well and provide good quality of service
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={RATING.efficiency}
            onChange={(event, newValue) => {
              handleRating(newValue, 'efficiency')
            }}
          >
            <Stack direction="row" sx={{m: '0 !important'}}>
              <FormControlLabel
                sx={{m: '0 !important', position: 'flex', alignItems: 'center'}}
                value={1}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'gold'}} />}
                    checkedIcon={<Star sx={{color: 'gold'}} />}
                  />
                }
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'black'}} />}
                    checkedIcon={<Star sx={{color: 'black'}} />}
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </Stack>
        <Stack spacing={3} sx={{mb: 1, position: 'flex', alignItems: 'center'}}>
          <Typography variant="h5" color="text.secondary" sx={{display: 'block', fontWeight: '600'}}>
            Recommendable
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{mt: '0 !important'}}>
            The Jobster is highly recommendable
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={RATING.recommendable}
            onChange={(event, newValue) => {
              handleRating(newValue, 'recommendable')
            }}
          >
            <Stack direction="row" sx={{m: '0 !important'}}>
              <FormControlLabel
                sx={{m: '0 !important', position: 'flex', alignItems: 'center'}}
                value={1}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'gold'}} />}
                    checkedIcon={<Star sx={{color: 'gold'}} />}
                  />
                }
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    size="large"
                    icon={<StarBorder sx={{color: 'black'}} />}
                    checkedIcon={<Star sx={{color: 'black'}} />}
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </Stack>
        <Stack spacing={3} sx={{mb: 1}}>
          <TextField
            label="Comments (optional)"
            key="comments"
            rows={6}
            fullWidth
            multiline
            sx={{mt: 3}}
            {...getFieldProps('comments')}
          />
        </Stack>

        <Stack spacing={3} sx={{my: 2}}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
