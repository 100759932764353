import React from 'react'
import {useNavigate} from '@reach/router'
import {Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Stack, Box} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import PropTypes from 'prop-types'

import SuccessIllustration from 'assets/static/illustrations/check.webp'

const RepostDialog = ({open, handleClose}) => {
  const navigate = useNavigate()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{textAlign: 'center', pt: 5, pb: 0}}>
        <Typography variant="h4" component="span">
          Gig Cancel Success.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box component="img" src={SuccessIllustration} sx={{mt: 2, width: '80%', mx: 'auto', objectFit: 'contain'}} />
      </DialogContent>
      <DialogActions sx={{display: 'block', pb: 5, px: 5}}>
        <Stack direction="column" spacing={2} alignItems="center">
          <Button onClick={() => navigate('/client/gig/create')} variant="outlined" color="inherit">
            Repost a Gig
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => navigate('/client/gig/create?tab=1')}
            sx={{mt: 2}}
          >
            Go to Dashboard
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

RepostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default RepostDialog
