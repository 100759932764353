import React, {useEffect, useState, createContext} from 'react'
import PropTypes from 'prop-types'
import {Box, Typography, Dialog, Stack} from '@mui/material'
import {Global} from '@emotion/react'
import {styled} from '@mui/styles'
import {grey} from '@mui/material/colors'

// components
import {AppSurvey} from 'components/surveys'

// hooks
import {useAuth} from 'contexts/AuthContext'

const drawerBleeding = 56

const StyledBox = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800]
}))

const SurveyContext = createContext()

const SurveyProvider = ({children}) => {
  const {currentUser} = useAuth()
  const [open, setOpen] = useState(false)
  const [survey, setSurvey] = useState([])
  const [GIG_ID, setGigId] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const toggleSurvey = (newOpen, gigId) => {
    // if (!gigId) return
    setGigId(gigId)
    setOpen(newOpen)
  }

  const onOpen = () => {}

  useEffect(() => {
    if (open) {
      onOpen()
    }
  }, [open])

  return (
    <SurveyContext.Provider value={{survey, setSurvey, toggleSurvey}}>
      {children}
      <Global
        styles={{
          '.rating-dialog > .MuiDialog-paper': {
            height: `calc(80%)`,
            overflow: 'visible'
          }
        }}
      />
      <Box
        sx={{
          zIndex: !open ? 999 : -1,
          backgroundColor: '#FFF',
          overflow: 'hidden',
          borderRadius: '6px'
        }}
      >
        <Dialog
          open={open || false}
          onClose={() => {}} // Ignore attempts to close the dialog
          // onOpen={onOpen}
          className="survey-dialog"
        >
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
              backgroundColor: success ? '#fff' : '#1976D2 !important',
              transition: 'background-color 0.3s ease-in-out' // Smooth transition effect
            }}
          >
            <Box sx={{py: 2, '& > legend': {mt: 2}, alignContent: 'center'}}>
              <AppSurvey
                user={currentUser}
                gigId={GIG_ID}
                onClose={() => {
                  setOpen(false)
                  setSuccess(false)
                }}
                onOpenSuccess={() => setSuccess(true)}
              />
            </Box>
          </StyledBox>
        </Dialog>
      </Box>
    </SurveyContext.Provider>
  )
}

SurveyProvider.propTypes = {
  children: PropTypes.node
}

export {SurveyContext, SurveyProvider}
