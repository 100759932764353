let provincialRate = {
  sss: 5.53, //prev 4.3365385, // prev 4.3365385
  pagibig: 0.48, // prev 0.7211538
  philhealth: 1.18 // 0.4807692
}

let ncrRate = {
  sss: 7.0, //prev 6.274033846, // prev 5.7692308,
  pagibig: 0.48, //prev 1.34682692, // prev 1.0096154
  philhealth: 1.43 // prev 0.4807692
}
export function default_calculations(hours, fee, locationRate, volFee, nightSurge, is30Percent, is100Percent) {
  let computedFeeByHr = parseFloat(hours * fee)
  // let voluntaryFee =
  //   parseFloat(hours * ncrRate.sss) + parseFloat(hours * ncrRate.pagibig) + parseFloat(hours * ncrRate.philhealth)
  const voluntaryFee = parseFloat(volFee * hours)
  let premiumFee = parseFloat(nightSurge) + parseFloat(is30Percent) + parseFloat(is100Percent)
  let appFee = parseFloat(hours * 1.25)
  let transactionFee = parseFloat(computedFeeByHr + voluntaryFee + appFee) * 0.1 // 10%
  let grossGigFee = parseFloat(computedFeeByHr + voluntaryFee + appFee + transactionFee + premiumFee)
  let grossVAT = parseFloat(grossGigFee * 0.12) // 12%
  let grossWithHolding = parseFloat(grossGigFee * 0.02) // 2%
  let serviceCost = parseFloat(grossGigFee + grossVAT - grossWithHolding)
  let jobsterTotal = parseFloat(fee) + parseFloat(volFee)
  return {
    computedFeeByHr,
    voluntaryFee,
    appFee,
    transactionFee,
    grossGigFee,
    grossVAT,
    grossWithHolding,
    serviceCost,
    jobsterTotal,
    premiumFee
  }
}
export function calculations(hours, fee, locationRate, gigOffered, postingDays) {
  const totalGigFee = parseFloat(fee)
  //console.log('🚀 ~ file: gigComputation.js:40 ~ calculations ~ totalGigFee:', totalGigFee)
  const dailyRate = parseFloat(fee)
  let totalGigFeeDaily = dailyRate * postingDays
  let totalGigFeeHourly = parseFloat(fee) * hours
  //console.log('🚀 ~ file: gigComputation.js:43 ~ calculations ~ totalGigFeeHourly:', totalGigFeeHourly)
  let transactionFee
  const voluntaryFee = 90.41
  const appFee = parseFloat(hours * 1.25)
  let feePerHr
  if (gigOffered === 'Hourly' || gigOffered == undefined) {
    feePerHr = parseFloat(fee)
  } else {
    feePerHr = (parseFloat(fee) - parseFloat(voluntaryFee).toFixed(2)) / hours
  }
  const computedFeeByHr = totalGigFee
  const computedDaily = totalGigFeeDaily - parseFloat(voluntaryFee)
  const computedHourly = totalGigFeeHourly - parseFloat(voluntaryFee)

  if (gigOffered === 'By-the-job') {
    transactionFee = parseFloat(totalGigFee + appFee) * 0.1
  } else if (gigOffered === 'Hourly') {
    transactionFee = parseFloat(totalGigFeeHourly + appFee) * 0.1
  } else if (gigOffered === 'Daily') {
    transactionFee = parseFloat(totalGigFeeDaily + appFee) * 0.1
  } else {
    transactionFee = 0
  }
  // console.log('🚀 ~ file: gigComputation.js:66 ~ calculations ~ transactionFee:', transactionFee)

  // let txnFeeDaily = parseFloat(totalGigFeeDaily + appFee + voluntaryFee) * 0.1
  // console.log('🚀 ~ file: gigComputation.js:35 ~ calculations ~ txnFeeDaily:', txnFeeDaily)
  const grossGigFee = totalGigFee + appFee + parseFloat(transactionFee)
  const grossGigDaily = totalGigFeeDaily + appFee + parseFloat(transactionFee)
  const grossGigHourly = totalGigFeeHourly + appFee + parseFloat(transactionFee)
  const grossVAT =
    gigOffered === 'Daily' ? grossGigDaily * 0.12 : gigOffered === 'Hourly' ? grossGigHourly * 0.12 : grossGigFee * 0.12
  const grossWithHolding =
    gigOffered === 'Daily' ? grossGigDaily * 0.02 : gigOffered === 'Hourly' ? grossGigHourly * 0.02 : grossGigFee * 0.02

  const serviceCost =
    gigOffered === 'Daily'
      ? grossGigDaily + grossVAT - grossWithHolding
      : gigOffered === 'Hourly'
      ? grossGigHourly + grossVAT - grossWithHolding
      : grossGigFee + grossVAT - grossWithHolding

  const jobsterTotal = parseFloat(fee)

  return {
    computedFeeByHr,
    computedDaily,
    computedHourly,
    dailyRate,
    grossGigDaily,
    grossGigHourly,
    voluntaryFee,
    appFee,
    transactionFee,
    grossGigFee,
    grossVAT,
    grossWithHolding,
    serviceCost,
    jobsterTotal
  }
}

export function parcel_calculations(hours, fee) {
  let computedFeeByHr = parseFloat(hours * fee)
  let txnFee = parseFloat(computedFeeByHr * 0.06) // 6%
  let grossGigFee = parseFloat(computedFeeByHr + txnFee)
  let grossVAT = parseFloat(grossGigFee * 0.12) // 12%
  let grossWithHolding = parseFloat(grossGigFee * 0.02) // 2%
  let serviceCost = parseFloat(grossGigFee + grossVAT - grossWithHolding)

  return {
    computedFeeByHr,
    txnFee,
    grossGigFee,
    grossVAT,
    grossWithHolding,
    serviceCost
  }
}

export function endShift_calculations(hours, fee, volFee, premFee, holiday, late, gigExtentionHr, nightSurgeHr) {
  if (!hours || hours === '0.00') return 0
  let lateByHours = parseFloat(late) / parseFloat(60)
  if (late === null || late === undefined) {
    lateByHours = 0
  }
  let holidaySurge
  if (!holiday) {
    holidaySurge = parseFloat(0)
  } else {
    holidaySurge = parseFloat(holiday)
  }

  const lateDeduction = parseFloat(lateByHours * fee)
  const finalHours = hours - lateByHours

  let computedFeeByHr = parseFloat(finalHours * fee)
  let voluntaryFee = parseFloat(volFee)
  // let basicRate = parseFloat(fee) - voluntaryFee
  // console.log('🚀 ~ file: computations.js:26 ~ basicRate:', basicRate)

  const nightSurge = parseFloat(fee) * 0.1 * parseFloat(nightSurgeHr)
  const gigExtension = parseFloat(fee) * 1.25 * parseFloat(gigExtentionHr)

  let premiumFee = parseFloat(premFee)
  let appFee = parseFloat(finalHours * 1.25)
  let transactionFee = parseFloat(computedFeeByHr + voluntaryFee + appFee) * 0.1 // 10%
  let grossGigFee = parseFloat(
    computedFeeByHr + voluntaryFee + appFee + transactionFee + premiumFee + nightSurge + gigExtension + holidaySurge
  )

  let grossVAT = parseFloat(grossGigFee * 0.12) // 12%
  let grossWithHolding = parseFloat(grossGigFee * 0.02) // 2%
  let serviceCost = parseFloat(grossGigFee + grossVAT - grossWithHolding)
  // let jobsterTotal = parseFloat(fee) + voluntaryFee / hours
  let jobsterFinal = parseFloat(computedFeeByHr + voluntaryFee + nightSurge + gigExtension + holidaySurge)
  return {
    computedFeeByHr,
    voluntaryFee,
    appFee,
    transactionFee,
    grossGigFee,
    grossVAT,
    grossWithHolding,
    serviceCost,
    // jobsterTotal,
    premiumFee,
    nightSurge,
    gigExtension,
    jobsterFinal,
    holidaySurge,
    lateDeduction
  }
}
