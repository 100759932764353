const key = 'token'
const user = 'user'
const notifDoc = 'notifDoc'
const refreshToken = 'refreshToken'
const fcmToken = 'fcmToken'

const storeToken = (token) => {
  try {
    localStorage.setItem(key, token)
  } catch (error) {
    console.log('Error storing the auth token', error)
  }
}

const storeRefreshToken = (token) => {
  try {
    localStorage.setItem(refreshToken, token)
  } catch (error) {
    console.log('Error storing the auth token', error)
  }
}

const storeUser = (data) => {
  try {
    localStorage.setItem(user, JSON.stringify(data))
  } catch (error) {
    console.log('Error storing the user', error)
  }
}

const storeFCM = (token) => {
  try {
    localStorage.setItem(fcmToken, token)
  } catch (error) {
    console.log('Error storing the fcm token', error)
  }
}
const getToken = () => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    console.log('Error getting the auth token', error)
  }
}

const getUser = () => {
  try {
    return localStorage.getItem(user)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const getFCMToken = () => {
  try {
    return localStorage.getItem(fcmToken)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const removeNotifDoc = () => {
  try {
    window.localStorage.removeItem('notifDoc')
  } catch (error) {
    console.log('Error removing the token', error)
  }
}

const remove = () => {
  try {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('uid')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('sid')
    window.localStorage.removeItem('clientData')
    window.localStorage.removeItem('jobsterData')
    window.localStorage.removeItem(fcmToken)
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('gigs')
    window.localStorage.removeItem('notifDoc')
    window.localStorage.removeItem('isSurveyCompleted')
    window.localStorage.removeItem('payment')
  } catch (error) {
    console.log('Error removing the token', error)
  }
}

const getRefreshToken = () => {
  try {
    return localStorage.getItem(refreshToken)
  } catch (error) {
    console.log('Error getting the store', error)
  }
}

const storeNotifDoc = (data) => {
  try {
    window.localStorage.setItem(notifDoc, JSON.stringify(data))
  } catch (error) {
    console.log('Error storing the user', error)
  }
}

const getNotifDoc = () => {
  try {
    return window.localStorage.getItem(notifDoc)
  } catch (error) {
    console.log('Error getting the store', error)
  }
}

const _expObject = {
  getToken,
  getUser,
  remove,
  storeToken,
  storeUser,
  storeRefreshToken,
  getRefreshToken,
  storeFCM,
  getFCMToken,
  storeNotifDoc,
  getNotifDoc,
  removeNotifDoc
}

export default _expObject
