import * as Yup from 'yup'
import React, {useState, useEffect} from 'react'
import {useFormik, Form, FormikProvider} from 'formik'
// material
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Rating from '@mui/material/Rating'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'

import {LoadingButton} from '@mui/lab'
import {useSnackbar} from 'notistack'

// api
import users_api from 'libs/endpoints/users'

// sanjoph
import Wink from 'assets/static/sanjoph-wink.png'
import HalfPose from 'assets/static/sanjoph-pose-half.png'
import Phone from 'assets/static/sanjoph-phone.png'
import SanjophStar from 'assets/static/sanjoph-star.png'

import {fCamelCase} from 'utils/formatCase'

import PropTypes from 'prop-types'
import {Star, StarBorder} from '@mui/icons-material'

import {styled} from '@mui/system'

AppSurvey.propTypes = {
  user: PropTypes.object,
  gigId: PropTypes.string,
  onClose: PropTypes.func,
  onOpenSuccess: PropTypes.func
}

const SubmitButton = styled(Button)({
  backgroundColor: '#FFD700',
  color: 'black',
  fontWeight: 'bold',
  borderRadius: '20px',
  padding: '10px 20px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: '#FFC107'
  }
})
export default function AppSurvey({gigId, onClose, onOpenSuccess}) {
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setLoading] = useState(false)
  const [RATING, setRating] = useState()
  const [open, setOpen] = useState(false)

  const GigSchema = Yup.object().shape({
    comments: Yup.string()
  })

  useEffect(() => {
    if (open && onOpenSuccess) {
      onOpenSuccess()
    }
  }, [open, onOpenSuccess])

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: GigSchema,
    onSubmit: async (values) => {
      setLoading(true)

      let data = {
        survey: 'App Feedback',
        rating: RATING,
        comments: values.comments || ''
      }
      const request = await users_api.post_user_survey(data)
      console.log('requestxx', request)
      if (!request.ok) {
        enqueueSnackbar(`Something went wrong in submitting your survey feedback - ${request.message}`, {
          variant: 'error'
        })
        return setLoading(false)
      }

      setLoading(false)
      enqueueSnackbar(`Thank you for your feedback`, {variant: 'success'})
      setOpen(true)
      // onClose()
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000)
    }
  })

  const {handleSubmit, getFieldProps} = formik

  const handleRating = (value, type) => {
    setRating((prev) => ({...prev, [type]: value}))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <FormikProvider value={formik}>
        {!open ? (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              {!RATING && (
                <>
                  <Typography variant="h6" sx={{color: '#fff', fontWeight: 'bold'}}>
                    Rate our app, <br /> tell us what you think.
                  </Typography>
                  <Typography variant="body2" sx={{color: '#fff', marginBottom: '15px'}}>
                    We'd love to hear your feedback!
                  </Typography>
                </>
              )}

              {/* Star Rating */}
              {RATING && (
                <>
                  <Box
                    component="img"
                    src={HalfPose} // Replace with your image path
                    alt="Sanjoph-pose-half"
                    sx={{
                      width: '70px', // Adjust image size
                      height: '70px',
                      display: 'block', // Ensures proper centering
                      margin: '0 auto'
                    }}
                  />
                  <Typography variant="h6" sx={{color: '#fff', fontWeight: 'bold'}}>
                    Thanks for your feedback.
                  </Typography>
                  <Typography variant="body2" sx={{color: '#fff', marginBottom: '15px'}}>
                    You can also write a review
                  </Typography>
                </>
              )}
              <Rating value={RATING} onChange={(event, newValue) => setRating(newValue)} sx={{fontSize: '3rem'}} />
              {RATING && (
                <>
                  <Typography variant="body2" sx={{color: '#fff'}}>
                    Leave a review
                  </Typography>
                  <TextField
                    multiline
                    rows={3}
                    placeholder="Feedbacks..."
                    variant="outlined"
                    fullWidth
                    value={formik.values.comments} // Bind Formik value
                    onChange={(e) => formik.setFieldValue('comments', e.target.value)}
                    sx={{
                      marginBottom: '15px',
                      marginTop: '15px', // Add some spacing above the textarea
                      backgroundColor: '#63A4FF', // Darker background for textarea
                      borderRadius: '4px', // Rounded corners

                      '& .MuiInputBase-input': {
                        color: '#fff' // Text color
                      },
                      '& .MuiInputLabel-root': {
                        color: '#aaa' // Placeholder color
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#ffd700' // Placeholder color when focused
                      }
                    }}
                    inputProps={{
                      sx: {
                        '&::placeholder': {
                          color: '#fff', // Placeholder color
                          opacity: 1 // Ensure the placeholder is fully visible
                        }
                      }
                    }}
                  />
                </>
              )}

              <SubmitButton
                fullWidth
                onClick={handleSubmit}
                sx={{
                  fontSize: '0.8rem', // Smaller text
                  padding: '5px 1px', // Less padding
                  width: 'auto',
                  minWidth: '100px', // Adjust button width
                  margin: '0 auto', // Center horizontally
                  display: 'block', // Ensure margin auto works
                  marginTop: '20px',
                  marginBottom: '20px'
                }}
                disabled={!RATING || isLoading}
              >
                Submit
              </SubmitButton>
              {!RATING && (
                <Box
                  component="img"
                  src={Wink} // Replace with your image path
                  alt="Sanjoph-wink"
                  sx={{
                    position: 'absolute',
                    bottom: 0, // Adjust distance from bottom
                    right: 0, // Adjust distance from right
                    width: '85px', // Adjust image size
                    height: '85px'
                  }}
                />
              )}

              {open && (
                <>
                  <Box
                    component="img"
                    src={HalfPose} // Replace with your image path
                    alt="Sanjoph-pose-half"
                    sx={{
                      width: '70px', // Adjust image size
                      height: '70px',
                      display: 'block', // Ensures proper centering
                      margin: '0 auto'
                    }}
                  />
                </>
              )}
            </Form>
          </>
        ) : (
          <>
            <Box
              component="img"
              src={SanjophStar} // Replace with your image path
              alt="Sanjoph-star"
              sx={{
                width: '180px', // Adjust image size
                height: 'auto',
                display: 'block', // Ensures proper centering
                margin: '20px 20px 0 20px'
              }}
            />
            <Typography variant="h6" sx={{marginTop: '15px', color: 'primary.main', fontWeight: 'bold'}}>
              Sent
            </Typography>
            <Typography variant="body2" sx={{marginBottom: '15px'}}>
              Thank you for your feedback
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(false)
                onClose()
              }}
            >
              Return
            </Button>
          </>
        )}
      </FormikProvider>
    </Box>
  )
}
